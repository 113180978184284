import React, { Component } from "react"
import Logo from "./resources/logo/kappa-slappa-thigh-low-resolution-logo-color-on-transparent-background.png"
import "./App.css"

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
         <img src={Logo} alt="Logo" className="ImageKST"/>
         <code>The proud owners of KappaSlappaThigh.org</code>
         <code>But, the fuck do we do with this site?</code>
        </header>
      </div>
    )
  }
}

export default App
